import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0a213d80 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _70b1c93e = () => interopDefault(import('../pages/help/about.vue' /* webpackChunkName: "pages/help/about" */))
const _0aefecc0 = () => interopDefault(import('../pages/help/about02.vue' /* webpackChunkName: "pages/help/about02" */))
const _5b276cb6 = () => interopDefault(import('../pages/help/agent.vue' /* webpackChunkName: "pages/help/agent" */))
const _ad09a380 = () => interopDefault(import('../pages/help/appset.vue' /* webpackChunkName: "pages/help/appset" */))
const _4f65558e = () => interopDefault(import('../pages/help/check.vue' /* webpackChunkName: "pages/help/check" */))
const _e0790e8c = () => interopDefault(import('../pages/help/disclaimer.vue' /* webpackChunkName: "pages/help/disclaimer" */))
const _a758a588 = () => interopDefault(import('../pages/help/disclaimer02.vue' /* webpackChunkName: "pages/help/disclaimer02" */))
const _5793d32c = () => interopDefault(import('../pages/help/maintain.vue' /* webpackChunkName: "pages/help/maintain" */))
const _5205ae96 = () => interopDefault(import('../pages/help/novice.vue' /* webpackChunkName: "pages/help/novice" */))
const _5a79f745 = () => interopDefault(import('../pages/help/question.vue' /* webpackChunkName: "pages/help/question" */))
const _1ae0d2db = () => interopDefault(import('../pages/help/rule.vue' /* webpackChunkName: "pages/help/rule" */))
const _34f9f9c6 = () => interopDefault(import('../pages/help/rule02.vue' /* webpackChunkName: "pages/help/rule02" */))
const _8effb8b6 = () => interopDefault(import('../pages/history.vue' /* webpackChunkName: "pages/history" */))
const _73fda457 = () => interopDefault(import('../pages/history/hisActivity.vue' /* webpackChunkName: "pages/history/hisActivity" */))
const _82237b0e = () => interopDefault(import('../pages/history/hisBet.vue' /* webpackChunkName: "pages/history/hisBet" */))
const _7170b3f4 = () => interopDefault(import('../pages/history/hisDeposit.vue' /* webpackChunkName: "pages/history/hisDeposit" */))
const _afea9b00 = () => interopDefault(import('../pages/history/hisExchPoint.vue' /* webpackChunkName: "pages/history/hisExchPoint" */))
const _2674ad8f = () => interopDefault(import('../pages/history/hisSale.vue' /* webpackChunkName: "pages/history/hisSale" */))
const _d9b6c99a = () => interopDefault(import('../pages/history/hisTransfer.vue' /* webpackChunkName: "pages/history/hisTransfer" */))
const _4af27b38 = () => interopDefault(import('../pages/mobile/index.vue' /* webpackChunkName: "pages/mobile/index" */))
const _1e36ff4c = () => interopDefault(import('../pages/password.vue' /* webpackChunkName: "pages/password" */))
const _48ca1d34 = () => interopDefault(import('../pages/password/chgpw.vue' /* webpackChunkName: "pages/password/chgpw" */))
const _8661a6fe = () => interopDefault(import('../pages/password/chgsafepw.vue' /* webpackChunkName: "pages/password/chgsafepw" */))
const _ddf388a0 = () => interopDefault(import('../pages/share.vue' /* webpackChunkName: "pages/share" */))
const _1276cf1c = () => interopDefault(import('../pages/share/hisShare.vue' /* webpackChunkName: "pages/share/hisShare" */))
const _efcdd280 = () => interopDefault(import('../pages/share/share.vue' /* webpackChunkName: "pages/share/share" */))
const _23454bae = () => interopDefault(import('../pages/system.vue' /* webpackChunkName: "pages/system" */))
const _27ae161f = () => interopDefault(import('../pages/system/info.vue' /* webpackChunkName: "pages/system/info" */))
const _1c760aa8 = () => interopDefault(import('../pages/system/mail.vue' /* webpackChunkName: "pages/system/mail" */))
const _2952050a = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _4b91eba5 = () => interopDefault(import('../pages/user/card.vue' /* webpackChunkName: "pages/user/card" */))
const _8402e2fe = () => interopDefault(import('../pages/user/payment.vue' /* webpackChunkName: "pages/user/payment" */))
const _24665ca4 = () => interopDefault(import('../pages/user/profile.vue' /* webpackChunkName: "pages/user/profile" */))
const _3c2d86e0 = () => interopDefault(import('../pages/user/user.vue' /* webpackChunkName: "pages/user/user" */))
const _42991df4 = () => interopDefault(import('../pages/user/virtual.vue' /* webpackChunkName: "pages/user/virtual" */))
const _dfed8f10 = () => interopDefault(import('../pages/wallet.vue' /* webpackChunkName: "pages/wallet" */))
const _58747bba = () => interopDefault(import('../pages/wallet/bank.vue' /* webpackChunkName: "pages/wallet/bank" */))
const _21114c87 = () => interopDefault(import('../pages/wallet/deposit.vue' /* webpackChunkName: "pages/wallet/deposit" */))
const _1314e728 = () => interopDefault(import('../pages/wallet/discount.vue' /* webpackChunkName: "pages/wallet/discount" */))
const _299da4d4 = () => interopDefault(import('../pages/wallet/treasure.vue' /* webpackChunkName: "pages/wallet/treasure" */))
const _169992f3 = () => interopDefault(import('../pages/account/agent_signup.vue' /* webpackChunkName: "pages/account/agent_signup" */))
const _2a8a3678 = () => interopDefault(import('../pages/account/forgot.vue' /* webpackChunkName: "pages/account/forgot" */))
const _59ab1050 = () => interopDefault(import('../pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _43031f8e = () => interopDefault(import('../pages/account/signup.vue' /* webpackChunkName: "pages/account/signup" */))
const _d9892f40 = () => interopDefault(import('../pages/activity/activity.vue' /* webpackChunkName: "pages/activity/activity" */))
const _c5badfe0 = () => interopDefault(import('../pages/event/FIFA2022.vue' /* webpackChunkName: "pages/event/FIFA2022" */))
const _4fa9c7b2 = () => interopDefault(import('../pages/event/luckyWheel.vue' /* webpackChunkName: "pages/event/luckyWheel" */))
const _15df7670 = () => interopDefault(import('../pages/event/luckyWheel/notice.vue' /* webpackChunkName: "pages/event/luckyWheel/notice" */))
const _040c611a = () => interopDefault(import('../pages/event/luckyWheel/wheel.vue' /* webpackChunkName: "pages/event/luckyWheel/wheel" */))
const _2b0c76ce = () => interopDefault(import('../pages/event/signInSheet.vue' /* webpackChunkName: "pages/event/signInSheet" */))
const _1daeeeca = () => interopDefault(import('../pages/game/animal.vue' /* webpackChunkName: "pages/game/animal" */))
const _f44c18f4 = () => interopDefault(import('../pages/game/battle.vue' /* webpackChunkName: "pages/game/battle" */))
const _0c00a2bc = () => interopDefault(import('../pages/game/chess.vue' /* webpackChunkName: "pages/game/chess" */))
const _785a0924 = () => interopDefault(import('../pages/game/chess02.vue' /* webpackChunkName: "pages/game/chess02" */))
const _47e7d40e = () => interopDefault(import('../pages/game/egame.vue' /* webpackChunkName: "pages/game/egame" */))
const _5352dc41 = () => interopDefault(import('../pages/game/egame/egame.vue' /* webpackChunkName: "pages/game/egame/egame" */))
const _53d142b4 = () => interopDefault(import('../pages/game/fish.vue' /* webpackChunkName: "pages/game/fish" */))
const _4a52af3a = () => interopDefault(import('../pages/game/live.vue' /* webpackChunkName: "pages/game/live" */))
const _0a3b37eb = () => interopDefault(import('../pages/game/lottery.vue' /* webpackChunkName: "pages/game/lottery" */))
const _425da75e = () => interopDefault(import('../pages/game/multiple.vue' /* webpackChunkName: "pages/game/multiple" */))
const _95e1e168 = () => interopDefault(import('../pages/game/slot.vue' /* webpackChunkName: "pages/game/slot" */))
const _e6b0d5d4 = () => interopDefault(import('../pages/game/sport.vue' /* webpackChunkName: "pages/game/sport" */))
const _0a712e50 = () => interopDefault(import('../pages/mobile/game.vue' /* webpackChunkName: "pages/mobile/game" */))
const _8211cd6a = () => interopDefault(import('../pages/mobile/game/animal.vue' /* webpackChunkName: "pages/mobile/game/animal" */))
const _27221e07 = () => interopDefault(import('../pages/mobile/game/battle.vue' /* webpackChunkName: "pages/mobile/game/battle" */))
const _0fb7b001 = () => interopDefault(import('../pages/mobile/game/chess.vue' /* webpackChunkName: "pages/mobile/game/chess" */))
const _1c77d150 = () => interopDefault(import('../pages/mobile/game/egame.vue' /* webpackChunkName: "pages/mobile/game/egame" */))
const _d68b9532 = () => interopDefault(import('../pages/mobile/game/fish.vue' /* webpackChunkName: "pages/mobile/game/fish" */))
const _65973c52 = () => interopDefault(import('../pages/mobile/game/jackpot.vue' /* webpackChunkName: "pages/mobile/game/jackpot" */))
const _08f585fb = () => interopDefault(import('../pages/mobile/game/live.vue' /* webpackChunkName: "pages/mobile/game/live" */))
const _dc0f44ec = () => interopDefault(import('../pages/mobile/game/lottery.vue' /* webpackChunkName: "pages/mobile/game/lottery" */))
const _9b7594c2 = () => interopDefault(import('../pages/mobile/game/multiple.vue' /* webpackChunkName: "pages/mobile/game/multiple" */))
const _73b1e60d = () => interopDefault(import('../pages/mobile/game/slot.vue' /* webpackChunkName: "pages/mobile/game/slot" */))
const _bb40d316 = () => interopDefault(import('../pages/mobile/game/sport.vue' /* webpackChunkName: "pages/mobile/game/sport" */))
const _44d1721f = () => interopDefault(import('../pages/mobile/help.vue' /* webpackChunkName: "pages/mobile/help" */))
const _f32c6ac6 = () => interopDefault(import('../pages/mobile/help/about.vue' /* webpackChunkName: "pages/mobile/help/about" */))
const _daa5db42 = () => interopDefault(import('../pages/mobile/help/about02.vue' /* webpackChunkName: "pages/mobile/help/about02" */))
const _70df6e15 = () => interopDefault(import('../pages/mobile/help/agent.vue' /* webpackChunkName: "pages/mobile/help/agent" */))
const _4ac358c1 = () => interopDefault(import('../pages/mobile/help/appset.vue' /* webpackChunkName: "pages/mobile/help/appset" */))
const _23f552d0 = () => interopDefault(import('../pages/mobile/help/check.vue' /* webpackChunkName: "pages/mobile/help/check" */))
const _b7ff0a8a = () => interopDefault(import('../pages/mobile/help/disclaimer.vue' /* webpackChunkName: "pages/mobile/help/disclaimer" */))
const _b54f9a06 = () => interopDefault(import('../pages/mobile/help/disclaimer02.vue' /* webpackChunkName: "pages/mobile/help/disclaimer02" */))
const _441da4ab = () => interopDefault(import('../pages/mobile/help/maintain.vue' /* webpackChunkName: "pages/mobile/help/maintain" */))
const _78455336 = () => interopDefault(import('../pages/mobile/help/novice.vue' /* webpackChunkName: "pages/mobile/help/novice" */))
const _6b3cf4f4 = () => interopDefault(import('../pages/mobile/help/question.vue' /* webpackChunkName: "pages/mobile/help/question" */))
const _4cf8acc8 = () => interopDefault(import('../pages/mobile/help/rule.vue' /* webpackChunkName: "pages/mobile/help/rule" */))
const _f269a4c4 = () => interopDefault(import('../pages/mobile/help/rule02.vue' /* webpackChunkName: "pages/mobile/help/rule02" */))
const _57bbb6a6 = () => interopDefault(import('../pages/mobile/history.vue' /* webpackChunkName: "pages/mobile/history" */))
const _3df7bdd8 = () => interopDefault(import('../pages/mobile/history/hisActivity.vue' /* webpackChunkName: "pages/mobile/history/hisActivity" */))
const _4bf87c58 = () => interopDefault(import('../pages/mobile/history/hisBet.vue' /* webpackChunkName: "pages/mobile/history/hisBet" */))
const _22584f36 = () => interopDefault(import('../pages/mobile/history/hisDeposit.vue' /* webpackChunkName: "pages/mobile/history/hisDeposit" */))
const _1d53c91f = () => interopDefault(import('../pages/mobile/history/hisExchPoint.vue' /* webpackChunkName: "pages/mobile/history/hisExchPoint" */))
const _3ab1af90 = () => interopDefault(import('../pages/mobile/history/hisSale.vue' /* webpackChunkName: "pages/mobile/history/hisSale" */))
const _5d1eb4b4 = () => interopDefault(import('../pages/mobile/history/hisTransfer.vue' /* webpackChunkName: "pages/mobile/history/hisTransfer" */))
const _391b4d79 = () => interopDefault(import('../pages/mobile/password.vue' /* webpackChunkName: "pages/mobile/password" */))
const _545751da = () => interopDefault(import('../pages/mobile/password/chgpw.vue' /* webpackChunkName: "pages/mobile/password/chgpw" */))
const _37494240 = () => interopDefault(import('../pages/mobile/password/chgsafepw.vue' /* webpackChunkName: "pages/mobile/password/chgsafepw" */))
const _a746ca1e = () => interopDefault(import('../pages/mobile/share.vue' /* webpackChunkName: "pages/mobile/share" */))
const _c0fdee0a = () => interopDefault(import('../pages/mobile/share/hisShare.vue' /* webpackChunkName: "pages/mobile/share/hisShare" */))
const _29614141 = () => interopDefault(import('../pages/mobile/share/share.vue' /* webpackChunkName: "pages/mobile/share/share" */))
const _72bad48d = () => interopDefault(import('../pages/mobile/system.vue' /* webpackChunkName: "pages/mobile/system" */))
const _6e137ec0 = () => interopDefault(import('../pages/mobile/system/info.vue' /* webpackChunkName: "pages/mobile/system/info" */))
const _848395ae = () => interopDefault(import('../pages/mobile/system/mail.vue' /* webpackChunkName: "pages/mobile/system/mail" */))
const _640239a9 = () => interopDefault(import('../pages/mobile/user.vue' /* webpackChunkName: "pages/mobile/user" */))
const _0a34c266 = () => interopDefault(import('../pages/mobile/user/card.vue' /* webpackChunkName: "pages/mobile/user/card" */))
const _45bbb420 = () => interopDefault(import('../pages/mobile/user/payment.vue' /* webpackChunkName: "pages/mobile/user/payment" */))
const _a7b8fb7a = () => interopDefault(import('../pages/mobile/user/profile.vue' /* webpackChunkName: "pages/mobile/user/profile" */))
const _0a5f44be = () => interopDefault(import('../pages/mobile/user/user.vue' /* webpackChunkName: "pages/mobile/user/user" */))
const _667096a5 = () => interopDefault(import('../pages/mobile/user/virtual.vue' /* webpackChunkName: "pages/mobile/user/virtual" */))
const _41027d52 = () => interopDefault(import('../pages/mobile/wallet.vue' /* webpackChunkName: "pages/mobile/wallet" */))
const _750deca4 = () => interopDefault(import('../pages/mobile/wallet/bank.vue' /* webpackChunkName: "pages/mobile/wallet/bank" */))
const _a3c8f334 = () => interopDefault(import('../pages/mobile/wallet/deposit.vue' /* webpackChunkName: "pages/mobile/wallet/deposit" */))
const _2751e929 = () => interopDefault(import('../pages/mobile/wallet/discount.vue' /* webpackChunkName: "pages/mobile/wallet/discount" */))
const _4179e514 = () => interopDefault(import('../pages/mobile/wallet/hisDeposit.vue' /* webpackChunkName: "pages/mobile/wallet/hisDeposit" */))
const _7f1b7941 = () => interopDefault(import('../pages/mobile/wallet/hisSale.vue' /* webpackChunkName: "pages/mobile/wallet/hisSale" */))
const _0123a0d2 = () => interopDefault(import('../pages/mobile/wallet/treasure.vue' /* webpackChunkName: "pages/mobile/wallet/treasure" */))
const _49c333f4 = () => interopDefault(import('../pages/event/signinSheet/info.vue' /* webpackChunkName: "pages/event/signinSheet/info" */))
const _09c60d9e = () => interopDefault(import('../pages/event/signinSheet/notice.vue' /* webpackChunkName: "pages/event/signinSheet/notice" */))
const _07cb9a89 = () => interopDefault(import('../pages/event/signinSheet/sheet.vue' /* webpackChunkName: "pages/event/signinSheet/sheet" */))
const _e83aacdc = () => interopDefault(import('../pages/mobile/account/agent_signup.vue' /* webpackChunkName: "pages/mobile/account/agent_signup" */))
const _77c51ea3 = () => interopDefault(import('../pages/mobile/account/forgot.vue' /* webpackChunkName: "pages/mobile/account/forgot" */))
const _43120619 = () => interopDefault(import('../pages/mobile/account/login.vue' /* webpackChunkName: "pages/mobile/account/login" */))
const _6b88aa18 = () => interopDefault(import('../pages/mobile/account/signup.vue' /* webpackChunkName: "pages/mobile/account/signup" */))
const _e78023be = () => interopDefault(import('../pages/mobile/activity/activity.vue' /* webpackChunkName: "pages/mobile/activity/activity" */))
const _5e3629ee = () => interopDefault(import('../pages/mobile/activity/activity_old.vue' /* webpackChunkName: "pages/mobile/activity/activity_old" */))
const _2a2cc9ef = () => interopDefault(import('../pages/mobile/event/FIFA2022.vue' /* webpackChunkName: "pages/mobile/event/FIFA2022" */))
const _4b8e5a46 = () => interopDefault(import('../pages/mobile/event/luckyWheel.vue' /* webpackChunkName: "pages/mobile/event/luckyWheel" */))
const _39036b4c = () => interopDefault(import('../pages/mobile/event/signInSheet.vue' /* webpackChunkName: "pages/mobile/event/signInSheet" */))
const _c6b4de1c = () => interopDefault(import('../pages/a/_code/index.vue' /* webpackChunkName: "pages/a/_code/index" */))
const _819f39ba = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/help",
    component: _0a213d80,
    name: "help",
    children: [{
      path: "about",
      component: _70b1c93e,
      name: "help-about"
    }, {
      path: "about02",
      component: _0aefecc0,
      name: "help-about02"
    }, {
      path: "agent",
      component: _5b276cb6,
      name: "help-agent"
    }, {
      path: "appset",
      component: _ad09a380,
      name: "help-appset"
    }, {
      path: "check",
      component: _4f65558e,
      name: "help-check"
    }, {
      path: "disclaimer",
      component: _e0790e8c,
      name: "help-disclaimer"
    }, {
      path: "disclaimer02",
      component: _a758a588,
      name: "help-disclaimer02"
    }, {
      path: "maintain",
      component: _5793d32c,
      name: "help-maintain"
    }, {
      path: "novice",
      component: _5205ae96,
      name: "help-novice"
    }, {
      path: "question",
      component: _5a79f745,
      name: "help-question"
    }, {
      path: "rule",
      component: _1ae0d2db,
      name: "help-rule"
    }, {
      path: "rule02",
      component: _34f9f9c6,
      name: "help-rule02"
    }]
  }, {
    path: "/history",
    component: _8effb8b6,
    name: "history",
    children: [{
      path: "hisActivity",
      component: _73fda457,
      name: "history-hisActivity"
    }, {
      path: "hisBet",
      component: _82237b0e,
      name: "history-hisBet"
    }, {
      path: "hisDeposit",
      component: _7170b3f4,
      name: "history-hisDeposit"
    }, {
      path: "hisExchPoint",
      component: _afea9b00,
      name: "history-hisExchPoint"
    }, {
      path: "hisSale",
      component: _2674ad8f,
      name: "history-hisSale"
    }, {
      path: "hisTransfer",
      component: _d9b6c99a,
      name: "history-hisTransfer"
    }]
  }, {
    path: "/mobile",
    component: _4af27b38,
    name: "mobile"
  }, {
    path: "/password",
    component: _1e36ff4c,
    name: "password",
    children: [{
      path: "chgpw",
      component: _48ca1d34,
      name: "password-chgpw"
    }, {
      path: "chgsafepw",
      component: _8661a6fe,
      name: "password-chgsafepw"
    }]
  }, {
    path: "/share",
    component: _ddf388a0,
    name: "share",
    children: [{
      path: "hisShare",
      component: _1276cf1c,
      name: "share-hisShare"
    }, {
      path: "share",
      component: _efcdd280,
      name: "share-share"
    }]
  }, {
    path: "/system",
    component: _23454bae,
    name: "system",
    children: [{
      path: "info",
      component: _27ae161f,
      name: "system-info"
    }, {
      path: "mail",
      component: _1c760aa8,
      name: "system-mail"
    }]
  }, {
    path: "/user",
    component: _2952050a,
    name: "user",
    children: [{
      path: "card",
      component: _4b91eba5,
      name: "user-card"
    }, {
      path: "payment",
      component: _8402e2fe,
      name: "user-payment"
    }, {
      path: "profile",
      component: _24665ca4,
      name: "user-profile"
    }, {
      path: "user",
      component: _3c2d86e0,
      name: "user-user"
    }, {
      path: "virtual",
      component: _42991df4,
      name: "user-virtual"
    }]
  }, {
    path: "/wallet",
    component: _dfed8f10,
    name: "wallet",
    children: [{
      path: "bank",
      component: _58747bba,
      name: "wallet-bank"
    }, {
      path: "deposit",
      component: _21114c87,
      name: "wallet-deposit"
    }, {
      path: "discount",
      component: _1314e728,
      name: "wallet-discount"
    }, {
      path: "treasure",
      component: _299da4d4,
      name: "wallet-treasure"
    }]
  }, {
    path: "/account/agent_signup",
    component: _169992f3,
    name: "account-agent_signup"
  }, {
    path: "/account/forgot",
    component: _2a8a3678,
    name: "account-forgot"
  }, {
    path: "/account/login",
    component: _59ab1050,
    name: "account-login"
  }, {
    path: "/account/signup",
    component: _43031f8e,
    name: "account-signup"
  }, {
    path: "/activity/activity",
    component: _d9892f40,
    name: "activity-activity"
  }, {
    path: "/event/FIFA2022",
    component: _c5badfe0,
    name: "event-FIFA2022"
  }, {
    path: "/event/luckyWheel",
    component: _4fa9c7b2,
    name: "event-luckyWheel",
    children: [{
      path: "notice",
      component: _15df7670,
      name: "event-luckyWheel-notice"
    }, {
      path: "wheel",
      component: _040c611a,
      name: "event-luckyWheel-wheel"
    }]
  }, {
    path: "/event/signInSheet",
    component: _2b0c76ce,
    name: "event-signInSheet"
  }, {
    path: "/game/animal",
    component: _1daeeeca,
    name: "game-animal"
  }, {
    path: "/game/battle",
    component: _f44c18f4,
    name: "game-battle"
  }, {
    path: "/game/chess",
    component: _0c00a2bc,
    name: "game-chess"
  }, {
    path: "/game/chess02",
    component: _785a0924,
    name: "game-chess02"
  }, {
    path: "/game/egame",
    component: _47e7d40e,
    name: "game-egame",
    children: [{
      path: "egame",
      component: _5352dc41,
      name: "game-egame-egame"
    }]
  }, {
    path: "/game/fish",
    component: _53d142b4,
    name: "game-fish"
  }, {
    path: "/game/live",
    component: _4a52af3a,
    name: "game-live"
  }, {
    path: "/game/lottery",
    component: _0a3b37eb,
    name: "game-lottery"
  }, {
    path: "/game/multiple",
    component: _425da75e,
    name: "game-multiple"
  }, {
    path: "/game/slot",
    component: _95e1e168,
    name: "game-slot"
  }, {
    path: "/game/sport",
    component: _e6b0d5d4,
    name: "game-sport"
  }, {
    path: "/mobile/game",
    component: _0a712e50,
    name: "mobile-game",
    children: [{
      path: "animal",
      component: _8211cd6a,
      name: "mobile-game-animal"
    }, {
      path: "battle",
      component: _27221e07,
      name: "mobile-game-battle"
    }, {
      path: "chess",
      component: _0fb7b001,
      name: "mobile-game-chess"
    }, {
      path: "egame",
      component: _1c77d150,
      name: "mobile-game-egame"
    }, {
      path: "fish",
      component: _d68b9532,
      name: "mobile-game-fish"
    }, {
      path: "jackpot",
      component: _65973c52,
      name: "mobile-game-jackpot"
    }, {
      path: "live",
      component: _08f585fb,
      name: "mobile-game-live"
    }, {
      path: "lottery",
      component: _dc0f44ec,
      name: "mobile-game-lottery"
    }, {
      path: "multiple",
      component: _9b7594c2,
      name: "mobile-game-multiple"
    }, {
      path: "slot",
      component: _73b1e60d,
      name: "mobile-game-slot"
    }, {
      path: "sport",
      component: _bb40d316,
      name: "mobile-game-sport"
    }]
  }, {
    path: "/mobile/help",
    component: _44d1721f,
    name: "mobile-help",
    children: [{
      path: "about",
      component: _f32c6ac6,
      name: "mobile-help-about"
    }, {
      path: "about02",
      component: _daa5db42,
      name: "mobile-help-about02"
    }, {
      path: "agent",
      component: _70df6e15,
      name: "mobile-help-agent"
    }, {
      path: "appset",
      component: _4ac358c1,
      name: "mobile-help-appset"
    }, {
      path: "check",
      component: _23f552d0,
      name: "mobile-help-check"
    }, {
      path: "disclaimer",
      component: _b7ff0a8a,
      name: "mobile-help-disclaimer"
    }, {
      path: "disclaimer02",
      component: _b54f9a06,
      name: "mobile-help-disclaimer02"
    }, {
      path: "maintain",
      component: _441da4ab,
      name: "mobile-help-maintain"
    }, {
      path: "novice",
      component: _78455336,
      name: "mobile-help-novice"
    }, {
      path: "question",
      component: _6b3cf4f4,
      name: "mobile-help-question"
    }, {
      path: "rule",
      component: _4cf8acc8,
      name: "mobile-help-rule"
    }, {
      path: "rule02",
      component: _f269a4c4,
      name: "mobile-help-rule02"
    }]
  }, {
    path: "/mobile/history",
    component: _57bbb6a6,
    name: "mobile-history",
    children: [{
      path: "hisActivity",
      component: _3df7bdd8,
      name: "mobile-history-hisActivity"
    }, {
      path: "hisBet",
      component: _4bf87c58,
      name: "mobile-history-hisBet"
    }, {
      path: "hisDeposit",
      component: _22584f36,
      name: "mobile-history-hisDeposit"
    }, {
      path: "hisExchPoint",
      component: _1d53c91f,
      name: "mobile-history-hisExchPoint"
    }, {
      path: "hisSale",
      component: _3ab1af90,
      name: "mobile-history-hisSale"
    }, {
      path: "hisTransfer",
      component: _5d1eb4b4,
      name: "mobile-history-hisTransfer"
    }]
  }, {
    path: "/mobile/password",
    component: _391b4d79,
    name: "mobile-password",
    children: [{
      path: "chgpw",
      component: _545751da,
      name: "mobile-password-chgpw"
    }, {
      path: "chgsafepw",
      component: _37494240,
      name: "mobile-password-chgsafepw"
    }]
  }, {
    path: "/mobile/share",
    component: _a746ca1e,
    name: "mobile-share",
    children: [{
      path: "hisShare",
      component: _c0fdee0a,
      name: "mobile-share-hisShare"
    }, {
      path: "share",
      component: _29614141,
      name: "mobile-share-share"
    }]
  }, {
    path: "/mobile/system",
    component: _72bad48d,
    name: "mobile-system",
    children: [{
      path: "info",
      component: _6e137ec0,
      name: "mobile-system-info"
    }, {
      path: "mail",
      component: _848395ae,
      name: "mobile-system-mail"
    }]
  }, {
    path: "/mobile/user",
    component: _640239a9,
    name: "mobile-user",
    children: [{
      path: "card",
      component: _0a34c266,
      name: "mobile-user-card"
    }, {
      path: "payment",
      component: _45bbb420,
      name: "mobile-user-payment"
    }, {
      path: "profile",
      component: _a7b8fb7a,
      name: "mobile-user-profile"
    }, {
      path: "user",
      component: _0a5f44be,
      name: "mobile-user-user"
    }, {
      path: "virtual",
      component: _667096a5,
      name: "mobile-user-virtual"
    }]
  }, {
    path: "/mobile/wallet",
    component: _41027d52,
    name: "mobile-wallet",
    children: [{
      path: "bank",
      component: _750deca4,
      name: "mobile-wallet-bank"
    }, {
      path: "deposit",
      component: _a3c8f334,
      name: "mobile-wallet-deposit"
    }, {
      path: "discount",
      component: _2751e929,
      name: "mobile-wallet-discount"
    }, {
      path: "hisDeposit",
      component: _4179e514,
      name: "mobile-wallet-hisDeposit"
    }, {
      path: "hisSale",
      component: _7f1b7941,
      name: "mobile-wallet-hisSale"
    }, {
      path: "treasure",
      component: _0123a0d2,
      name: "mobile-wallet-treasure"
    }]
  }, {
    path: "/event/signinSheet/info",
    component: _49c333f4,
    name: "event-signinSheet-info"
  }, {
    path: "/event/signinSheet/notice",
    component: _09c60d9e,
    name: "event-signinSheet-notice"
  }, {
    path: "/event/signinSheet/sheet",
    component: _07cb9a89,
    name: "event-signinSheet-sheet"
  }, {
    path: "/mobile/account/agent_signup",
    component: _e83aacdc,
    name: "mobile-account-agent_signup"
  }, {
    path: "/mobile/account/forgot",
    component: _77c51ea3,
    name: "mobile-account-forgot"
  }, {
    path: "/mobile/account/login",
    component: _43120619,
    name: "mobile-account-login"
  }, {
    path: "/mobile/account/signup",
    component: _6b88aa18,
    name: "mobile-account-signup"
  }, {
    path: "/mobile/activity/activity",
    component: _e78023be,
    name: "mobile-activity-activity"
  }, {
    path: "/mobile/activity/activity_old",
    component: _5e3629ee,
    name: "mobile-activity-activity_old"
  }, {
    path: "/mobile/event/FIFA2022",
    component: _2a2cc9ef,
    name: "mobile-event-FIFA2022"
  }, {
    path: "/mobile/event/luckyWheel",
    component: _4b8e5a46,
    name: "mobile-event-luckyWheel"
  }, {
    path: "/mobile/event/signInSheet",
    component: _39036b4c,
    name: "mobile-event-signInSheet"
  }, {
    path: "/a/:code",
    component: _c6b4de1c,
    name: "a-code"
  }, {
    path: "/",
    component: _819f39ba,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
